@import "antd/dist/antd.css";
@import "react-responsive-carousel/lib/styles/carousel.min.css";

@font-face {
    font-family: 'Work Sans';
    src: url('./assets/fonts/WorkSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('./assets/fonts/WorkSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('./assets/fonts/WorkSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('./assets/fonts/WorkSans-SemiBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

body {
    font-size: 14px;
    color: #000000;
    line-height: 1.6;
    font-family: 'Work Sans', sans-serif;
}

body a{
    color: #7a00e6;
    text-decoration: underline;
}

body a:hover {
     color: #22004b;
    text-decoration: underline;
}

